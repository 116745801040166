import React from "react";
import "./about.css";
const About = () => {
  return (
    <div className="container-fluid p-0">
      <div className="mainAbout">
        <div className="mainAboutHeading">
          <h1>KNOW ABOUT US.</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil cum
            exercitationem ratione dolorum. Fugit, odit suscipit quisquam
            incidunt reprehenderit nam.
          </p>
        </div>
      </div>
      <div className="container experience-area px-5">
        <div className="row">
          <div className="col-6">
            <div className="aboutTextLeft">
              <p className="aboutTextLeftHeading">
                SINCE 1984 IAM SERVING AS <br /> <span> LIFE COACHER.</span>
              </p>
              <p className="aboutTextLeftSubHeading">
                Positive behavioral supports is a behavior management system
                used to understand behavior.
              </p>
              <p className="aboutTextLeftText">
                Pleasure and praising pain was born and I will give you a
                complete account of the system, and expound the actual teachings
                of the great explorer of the truth, the master-builder of human
                happiness. Praising pain was born and I will give you a complete
                account of the system, and expound the actual teachings of the
                great explorer of the truth, the of human happiness great
                expound the actual teachings.
              </p>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-6">
                <div class="card">
                  <img
                    src="https://res.cloudinary.com/dkxzbqtzq/image/upload/v1702269096/vision_uas4s7.jpg"
                    alt=""
                  />
                  <div class="card-body">
                    <h5 class="card-title">Card title</h5>
                    <p class="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div class="card">
                  <img
                    src="https://res.cloudinary.com/dkxzbqtzq/image/upload/v1702269057/mission_nqbext.jpg"
                    alt=""
                  />
                  <div class="card-body">
                    <h5 class="card-title">Mission</h5>
                    <p class="card-text">
                      Great explorer of the truth, master builder of all human
                      happiness reject avoids us pleasure.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid Helping p-0">
        <div className="container HelpingDiv">
          <h1>Helping millions grow better.</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias
            quaerat, dolorem ipsam error ut cumque, pariatur atque assumenda
            saepe dicta vitae quidem in, temporibus quod quia excepturi. Labore,
            magnam aliquam. Neque impedit saepe recusandae qui ut? Quia autem
            sint blanditiis laudantium vero accusantium, sed non similique amet
            aut, officia asperiores voluptatibus, atque delectus! Dolorum omnis
            at modi rerum architecto molestias?
          </p>
        </div>
      </div>

      <div className="container-fluid founding">
        <div className="container px-5 my-5">
          <div className="row">
            <div className="col-5 foundingLeft p-4">
              <img src="https://res.cloudinary.com/dkxzbqtzq/image/upload/v1702269038/aboutimg4_jcosno.webp"className="img-fluid" alt="" />
            </div>
            <div className="col-7 foundingRight p-5 ">
              <h1>Our Founding</h1>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Necessitatibus nihil veritatis eum in doloribus maxime dolore
                error atque? Cupiditate eaque totam ex eum amet, qui magni
                aspernatur suscipit explicabo. Repellat, fugit distinctio.
                Molestias beatae molestiae perspiciatis eum ab incidunt
                obcaecati officiis minus doloribus autem dolor, veritatis cum,
                aliquam consectetur facilis! Quibusdam iusto, rem, doloremque
                mollitia impedit quia exercitationem repellendus ut amet nobis,
                omnis reprehenderit. Veniam iusto omnis dolorum, vitae
                accusantium est officia odit illum aspernatur quaerat iure
                reprehenderit corporis sunt!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid earlyGrowth">
        <div className="container px-5 my-5">
          <div className="row">
            <div className="col-7 earlyGrowthRight p-5">
              <h1>Our Founding</h1>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Necessitatibus nihil veritatis eum in doloribus maxime dolore
                error atque? Cupiditate eaque totam ex eum amet, qui magni
                aspernatur suscipit explicabo. Repellat, fugit distinctio.
                Molestias beatae molestiae perspiciatis eum ab incidunt
                obcaecati officiis minus doloribus autem dolor, veritatis cum,
                aliquam consectetur facilis! Quibusdam iusto, rem, doloremque
                mollitia impedit quia exercitationem repellendus ut amet nobis,
                omnis reprehenderit. Veniam iusto omnis dolorum, vitae
                accusantium est officia odit illum aspernatur quaerat iure
                reprehenderit corporis sunt!
              </p>
            </div>
            <div className="col-5 earlyGrowthLeft p-5">
              <img src="https://res.cloudinary.com/dkxzbqtzq/image/upload/v1702269037/aboutimg2_io7ext.webp" className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid series">
        <div className="container px-5 mt-5">
          <div className="row">
            <div className="col-5 seriesRight p-5">
              <img src="https://res.cloudinary.com/dkxzbqtzq/image/upload/v1702269038/aboutimg3_nluipp.webp" className="img-fluid" alt="" />
            </div>
            <div className="col-7 seriesLeft p-5">
              <h1>Our Founding</h1>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Necessitatibus nihil veritatis eum in doloribus maxime dolore
                error atque? Cupiditate eaque totam ex eum amet, qui magni
                aspernatur suscipit explicabo. Repellat, fugit distinctio.
                Molestias beatae molestiae perspiciatis eum ab incidunt
                obcaecati officiis minus doloribus autem dolor, veritatis cum,
                aliquam consectetur facilis! Quibusdam iusto, rem, doloremque
                mollitia impedit quia exercitationem repellendus ut amet nobis,
                omnis reprehenderit. Veniam iusto omnis dolorum, vitae
                accusantium est officia odit illum aspernatur quaerat iure
                reprehenderit corporis sunt!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid page-section">
        <div className="container p-5">
          <div className="row">
            <div className="col-7 pageSectionLeft">
              <h1>BrainTame</h1>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga ex
                repellat sed adipisci accusantium qui a beatae perspiciatis?
                Placeat neque asperiores perspiciatis, quam explicabo, nostrum
                ratione facere, dolor numquam hic adipisci aliquid aliquam ipsum
                ipsam amet pariatur quod iste voluptate.
              </p>
            </div>
            <div className="col-5 pageSectionRight">
              <img src="https://res.cloudinary.com/dkxzbqtzq/image/upload/v1702270523/logo_rwy45h.png" className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
