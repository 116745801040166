import React from "react";
import "./navbar.css"
import { Link } from "react-router-dom";
const Navbar = () => {
  return (
    <div className="navv">
      <div className="logo">
        <img src="https://res.cloudinary.com/dkxzbqtzq/image/upload/v1702270523/logo_rwy45h.png" alt="" />
      </div>
      <ul className="menu">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/service">Services</Link></li>
        <li><Link to="/contact">Contact</Link></li>
      </ul>
    </div>
  );
};

export default Navbar;
