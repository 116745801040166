import React from "react";
import "./footer.css";
import { FaFacebookF } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="container-fluid footer">
      <div className="container">
        <div className="row">
          <div className="col-6 footerLeft">
            <img src="https://res.cloudinary.com/dkxzbqtzq/image/upload/v1702270523/logo_rwy45h.png" className="footerLogo" alt="" />
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quam
              illo, voluptate nulla maiores doloribus nobis illum ducimus ipsa
              recusandae cupiditate.
            </p>
            <ul className="socialIcons">
              <li>
                <FaFacebookF />
              </li>
              <li>
                <AiFillInstagram />
              </li>
              <li>
                <FaTwitter />
              </li>
            </ul>
          </div>
          <div className="col-6 footerRight">
            <div className="footerLinks">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>

            <div className="footerForm">
              <input type="text" placeholder="Your Email Address" />
              <button>Subscribe</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
