import React from "react";
import "./style.css";
import { IoLocation } from "react-icons/io5";
import { IoCall } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
const Contact = () => {
  return (
    <div className="container-fluid p-0">
      <div className="mainAbout">
        <div className="mainAboutHeading">
          <h1>GET TOUCH WITH US</h1>
        </div>
      </div>
      <div className="container contact">
        <div className="row">
          <div className="col-8 contactleft">
            <h2 className="mt-2">DROP YOUR MESSAGE HERE</h2>
            <p className="mt-4">
              We recently helped a small business grow from break-even to over
              $1 Million profit in less than 2 years, Have a general question,
              find below contact details .
            </p>
            <div className="contactform">
              <div className="row">
                <div className="col-6">
                  <input type="text" placeholder="Your Name*" />
                </div>
                <div className="col-6">
                  <input type="text" placeholder="Phone*" />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col-12">
                      <input type="text" placeholder="Your Mail*" />
                    </div>
                    <div className="col-12">
                      <input type="text" placeholder="Subject *" />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="5"
                    placeholder="Your Message"
                  ></textarea>
                </div>
                <div className="col-12">
                  <button>SUBMIT NOW</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4 contactright">
            <div className="contactInfo">
              <img src="https://res.cloudinary.com/dkxzbqtzq/image/upload/v1702269064/contactimage_va6etg.png"alt="" />
              <h3 className="contactName">Jenifer Hearly</h3>
              <p className="contactprofile">Consultant</p>
              <span className="contactEmail">Jernifer@gmail.com</span>
            </div>
            <div className="contactDetails">
              <div className="contactAddress">
                <div className="contactIcon">
                <IoLocation />
                </div>
                <div className="officeAddress">
                  <p>
                    <span>Address: </span>32, 2nd Breaking Stret, Newyork, USA
                  </p>
                </div>
              </div>
              <div className="contactAddress">
                <div className="contactIcon">
                    <IoCall/>
                </div>
                <div className="officeNumber">
                  <p>
                    <span>Call: </span>+91 987654321
                  </p>
                </div>
              </div>
              <div className="contactAddress">
                <div className="contactIcon">
                <IoMdMail />
                </div>
                <div className="OfficeEmail">
                  <p>
                    <span>Mail: </span>Supportteam@gmail.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
