import React from "react";
import "./home.css";

import { Link } from "react-router-dom";
const Home = () => {
  return (
    <div className="home">
      {/* Banner */}
      <div className="mainBanner">
        <div className="container">
          <div id="carouselExampleIndicators" class="carousel slide">
            <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="3"
                aria-label="Slide 4"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="4"
                aria-label="Slide 5"
              ></button>
            </div>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div className="mainContent">
                  <h1>
                    Welcome to Brain Tame: <br /> Your Path to Continnuous
                    Improvment
                  </h1>
                  <div className="content1">W</div>
                </div>
              </div>
              <div class="carousel-item">
                <div className="mainContent">
                  <h1>
                    Elevating Workplace Culture with a Philosophy of Ongoing
                    Enhancement
                  </h1>
                  <div className="content1">E</div>
                </div>
              </div>
              <div class="carousel-item">
                <div className="mainContent">
                  <h1>
                    Customized Seminars and Workshops for Leadership Development
                  </h1>
                  <div className="content1">C</div>
                </div>
              </div>
              <div class="carousel-item">
                <div className="mainContent">
                  <h1>
                    Bespoke Training Programs <br /> in the Spirit of Continuous
                    Enhancement
                  </h1>
                  <div className="content1">B</div>
                </div>
              </div>
              <div class="carousel-item">
                <div className="mainContent">
                  <h1>
                    Diversity and Inclusion with a Commitment to Ongoing
                    Enhancement
                  </h1>
                  <div className="content1">D</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Text */}

      <div className="container typographySection">
        <div className="miniHeading">
          <p>WELCOME TO BRAIN TAME </p>
        </div>
        <div className="allTypologies">
          {/* First */}
          <div className="typologyOne">
            <p className="firstletter">C</p>
            <h1>
              CUSTOMIZED SEMINARS AND <br /> WORKSHOPS FOR LEADERSHIP <br />{" "}
              DEVELOPMENT
            </h1>
            <div className="row mt-5">
              <div className="col-6">
                <div className="typologyText">
                  <p>
                    In today's ever-changing professional landscape, we
                    specialize in tailoring seminars and workshops on
                    leadership, behavioral, and managerial skills for all levels
                    of leadership in corporate, public, and political spheres.
                    We empower individuals with cognitive and non-cognitive
                    skills crucial for success, aligning with the principles of
                    continuous improvement.
                  </p>
                  <div className="button">
                    <Link to="/about">Learn More</Link>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="seminarOne">
                  <img src="https://res.cloudinary.com/dkxzbqtzq/image/upload/v1702269088/seminar_y4u7qx.jpg" className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* Second */}
          <div className="typologyOne">
            <p className="firstletter">E</p>
            <h1>
              ELEVATING WORKPLACE CULTURE <br /> WITH A PHILOSOPHY OF ONGOING
              ENHANCEMENT
            </h1>
            <div className="row mt-5">
              <div className="col-6">
                <div className="seminarOne">
                  <img src="https://res.cloudinary.com/dkxzbqtzq/image/upload/v1702269088/seminar_y4u7qx.jpg" className="img-fluid" alt="" />
                </div>
              </div>
              <div className="col-6">
                <div className="typologyText">
                  <p>
                    In the modern workplace, success is not solely about
                    individual intellect but the synergy of a unified team, in
                    line with the philosophy of ongoing enhancement. Brain Tame
                    is your partner in cultivating an atmosphere of positivity
                    and collaboration. Our expert insights fortify both leaders
                    and team members, enhancing cognitive and socio-emotional
                    competencies, which resonate with the commitment to ongoing
                    improvement.
                  </p>
                  <div className="button">
                    <Link to="/about">Learn More</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Third */}
          <div className="typologyOne">
            <p className="firstletter">B</p>
            <h1>
              BESPOKE TRAINING PROGRAMS IN THE SPRIRIT OF CONTINUOUS ENHANCEMENT
            </h1>
            <div className="row mt-5">
              <div className="col-6">
                <div className="typologyText">
                  <p>
                    Every organization is unique, just as the principles of
                    continuous improvement emphasize ongoing enhancement. We
                    meticulously craft customized programs, aligning seamlessly
                    with your distinct aspirations and requirements. Whether
                    you're in the corporate, public, or political sector, our
                    tailored training ensures your institution flourishes with a
                    focus on ongoing improvement.
                  </p>
                  <div className="button">
                    <Link to="/about">Learn More</Link>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="seminarOne">
                  <img src="https://res.cloudinary.com/dkxzbqtzq/image/upload/v1702269090/seminar2_xs6bvk.jpg" className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* Fourth */}
          <div className="typologyOne">
            <p className="firstletter">D</p>
            <h1>
              DIVERSITY AND INCLUSION WITH A COMMITMENT TO ONGOING ENHANCEMENT
            </h1>
            <div className="row mt-5">
              <div className="col-6">
                <div className="seminarOne">
                  <img src="https://res.cloudinary.com/dkxzbqtzq/image/upload/v1702269090/seminar2_xs6bvk.jpg"className="img-fluid" alt="" />
                </div>
              </div>
              <div className="col-6">
                <div className="typologyText">
                  <p>
                    In today's evolving institutional landscape, diversity and
                    inclusion are paramount, as encouraged by the commitment to
                    ongoing enhancement. We help organizations embrace diverse
                    perspectives, fostering innovation, informed
                    decision-making, and a commitment to equal opportunity and
                    fairness. We also assist in creating a safe and secure
                    environment, including policies against workplace
                    harassment, aligning with the principles of ongoing
                    improvement.
                  </p>
                  <div className="button">
                    <Link to="/about">Learn More</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Fiveth */}
          <div className="typologyOne">
            <p className="firstletter">E</p>
            <h1>
              RESOURCE PROFESSIONALS OF EXCELLENCE COMMITTED TO ONGOING
              ENHANCEMENT
            </h1>
            <div className="row mt-5">
              <div className="col-6">
                <div className="typologyText">
                  <p>
                    At Brain Tame, we are privileged to have a team of experts,
                    nationally and internationally acclaimed. They not only
                    excel in their domains but communicate their expertise
                    effectively, in line with the spirit of continuous
                    improvement. With extensive training experience, they
                    deliver credible and impactful training services.
                  </p>
                  <div className="button">
                    <Link to="/about">Learn More</Link>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="seminarOne">
                  <img src="https://res.cloudinary.com/dkxzbqtzq/image/upload/v1702269090/seminar2_xs6bvk.jpg" className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* Fiveth */}
          <div className="typologyOne">
            <p className="firstletter">G</p>
            <h1>
              Guided Tours for Enlightening Exploration with a Focus on Ongoing
              Enhancement
            </h1>
            <div className="row mt-5">
              <div className="col-6">
                <div className="seminarOne">
                  <img src="https://res.cloudinary.com/dkxzbqtzq/image/upload/v1702269090/seminar2_xs6bvk.jpg" className="img-fluid" alt="" />
                </div>
              </div>
              <div className="col-6">
                <div className="typologyText">
                  <p>
                    Tourism is more than sightseeing; it's an educational
                    journey, reflecting the principles of ongoing enhancement.
                    We offer tailor-made tour packages that align with your
                    preferences, combining comfort with expert-guided tours. Our
                    information is a fusion of rigorous research and valuable
                    oral traditions, offering a deep understanding of your
                    destination with a focus on ongoing enhancement.
                  </p>
                  <div className="button">
                    <Link to="/about">Learn More</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="lastLine">
            <p>
              Choose <span>Brain Tame</span> for a journey toward professional
              excellence, inclusive environments, and enriching travel
              experiences, all in alignment with the philosophy of ongoing
              enhancement.
            </p>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />
    </div>
  );
};

export default Home;
