import React from "react";
import "./service.css";
const Service = () => {
  return (
    <div className="container-fluid services p-0">
      <div className="container serviceMain">
        <h1>
          Leadership is not an option for the Elite. It is an obligation for
          every person who interacts with others and themselves …
        </h1>
        <img src="https://res.cloudinary.com/dkxzbqtzq/image/upload/v1702269088/service_lbuxgc.gif" alt="" />
      
      </div>
      <div className="container-fluid headerContainer">
        <div className="container p-0">
          <p>
            HOW GOOD IS YOUR LEADERSHIP? DOMINIQUE HELPS YOU TO EMPOWER YOURSELF
            AND YOUR LEADERS, ENGAGE YOUR TEAMS, AND GET THE RESULTS YOU WANT,
            STEP BY STEP.
          </p>
        </div>
      </div>
      <div className="container serviceCard">
        <p className="serviceCardHeading">
          Through speaking, training and coaching, <br /> Dominique can help
          you:
        </p>
        <div className="row px-5 mt-5 justify-content-center">
          <div className="col-3 cardOne serviceCards mx-4">
            <h2>Step into your leadership</h2>
            <p>
              Develop soft skills to help engage staff and boost morale with
              effective one-to-one coaching to fit with your schedule.
            </p>
          </div>
          <div className="col-3 cardTwo serviceCards mx-4">
            <h2>Engage your teams</h2>
            <p>
              Open lines of communication between individuals and teams. Empower
              people to speak up, collaborate and become more energised.
            </p>
          </div>
          <div className="col-3 cardThree serviceCards mx-4">
            <h2>Transform the culture</h2>
            <p>
              Learn to spot dysfuctionning patterns. Create the space and
              systems needed to leverage people’s knowledge and experience.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid serivequot">
        <div className="container miniservice">
          <img src="https://res.cloudinary.com/dkxzbqtzq/image/upload/v1702269088/servicecoma_rk58ce.png"alt="" />
          <p className="miniserviceText">
            Dominique’s training was very interactive and created a safe
            environment for participants to feel part of the same group with the
            same mission and issues.
          </p>
          <p className="administrator">- Abc, Braintame</p>
        </div>
      </div>
      <div className="container-fluid serviceform">
        <div className="container px-5">
          <div className="row px-5">
            <div className="col-5 serviceformLeft">
              <p className="seriveformheading">Let's stay in touch!</p>
              <p className="seriveformtext">
                Subscribe to our fan list and get notified of interesting stuff.
                Type your data and press enter!
              </p>
              <form action="" className="serviceForm">
                <input type="" placeholder="First Name" />
                <input type="" placeholder="Last Name" />
                <input type="" placeholder="Email " />
              </form>
              <button>SUBSCRIBE ME</button>
            </div>
            <div className="col-6 serviceformRight">
              <p className="paraOne">
                Are you curious to learn about latest leadership trends and
                cultural changes ? I will never bother you with empty content.
              </p>
              <p className="paraTwo">
                Oh, and your e-mail address is safe with me, no sharing with 3rd
                parties.
              </p>
              <p className="paraThree">Thank you for your trust.</p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default Service;
